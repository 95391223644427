import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Host } from '../App';
import real_l from "../real_logo.png";
import { Link, Redirect, useParams } from "react-router-dom"
import ".././Responsive.css"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


function BOM_Dashboard() {
  const [date, setDate] = useState(new Date().toISOString());
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const [showPart, setShowPart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editable, seteditable] = useState(false);
  const [Date1, setDate1] = useState(new Date());
  const [Postartdate, setPoStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [Poenddate, setPoEnddate] = useState("2024-11-05");
  var token = (window.localStorage.getItem("acessToken"));
  var [customer, setCustomer] = useState("");
  var [Part_No, setPart_No] = useState("");
  var [Item_No, setItem_No] = useState("");
  var [Supplier, setSupplier] = useState("");
  const [data, setData] = useState([]);
  const [allFilters, setAllFilters] = useState([]);




  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const [bom, setBOM] = useState([{
    "Description": " ",
    "QTY": " ",
    "Receiving Date": "",
  }]);

  //setup for unique lists
  const uniqueCUSTOMER = [...new Set(allFilters.map(x => x.Customer))];
  const uniqueSupplier = [...new Set(allFilters.map(x => x.Supplier))];
  const uniqueItem_No = [...new Set(allFilters.map(x => x.Item_No))];
  const uniquePart_No = [...new Set(allFilters.map(x => x.Part_No))];

  const queryParams = {
    start_date: Postartdate,
    end_date: Poenddate,
    token: token,
    Item_No: Item_No,
    Customer: customer,
    Part_No: Part_No,
    Supplier: Supplier
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      Axios.get(`https://` + Host + `/BOM`, { params: queryParams }).then((data) => {

        if (data.data === "Error") {
          setIsLoading(false);
        }
        else {
          if (data.data.length > 0) {
            setBOM(data.data);
            setData(data.data);
          } else if (data.data.length <= 1) {
            window.alert("No Data Received!")
          }
        }
        setIsLoading(false);

      }, 2000);

      setTimeout(() => {
        setIsLoading(false)
      }, 3000)
    });

  }, [customer, Part_No, Supplier, Item_No])

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      Axios.get(`https://` + Host + `/BOM/allfilters`, { params: queryParams }).then((data) => {
        setAllFilters(data.data);
      }, 2000);

    });

  }, [])

  const toggleparts = () => {
    setShowPart(!showPart);
  }

  const Edit_toggle = () => {
    seteditable(!editable);
  }

  const handlePrint = () => {
    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          table tr:last-child td {
            font-weight: bold;
            background-color: lightgrey; /* Add light grey background color */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };

  const clearFilters = () => {
    setCustomer("");
    setItem_No("");
    setPart_No("");
    setSupplier("")
    const numberInputs = document.querySelectorAll('input[type="text"]');
    numberInputs.forEach(input => {
      input.value = '';
    });
  }
  const downloadCSV = () => {
    const filename = "BOM";
    // Convert data to CSV string
    const csv = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(bom));
    // Create a Blob object and download the file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, filename);

  };
  const [changedRows, setChangedRows] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateData(index, key, value);
  };

  const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedRows(prevRows => new Set(prevRows).add(index));
  };
  const print = () => {
    /*const changedData = [...changedRows].map(index => {
      return data[index];
    });*/
    console.log(data);
  }
  const Update = () => {
    const changedData = [...changedRows].map(index => {
      return data[index];
    });
    console.log(changedData);
    Axios.post(`https://` + Host + `/BOM/Update`, { changedData: JSON.stringify(changedData), token: token })
      .then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          seteditable(false);
        }

      })
      .catch(error => {
        window.alert("Error Occured")
      });

  }

  return (
    <div id="Bom_page">
      <center><h1>BOM Database</h1></center>
      <br></br>
      <button onClick={print}>Print</button>
      <center>{editable ? <button className="btn btn-primary" onClick={Update}>Update</button> : null}
        <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>
        <button class="btn btn-success" onClick={downloadCSV} id='mobileView'> Download As CSV </button></center>

      <tr id='Bom-filter'>

        <td class="col-1">
          <tr >
            <input
              type="text"
              className="form-control form-rounded"
              placeholder="Part No"
              onBlur={(e) => {
                setPart_No(e.target.value)
              }}
              list='PartNo'
            />
            <datalist id="PartNo">
              <option></option>
              {uniquePart_No.map(x => {
                return <option>{x}</option>
              })}
            </datalist>
          </tr>
          <tr>
            <input
              type="text"
              className="form-control form-rounded"
              placeholder="Item No"
              onBlur={(e) => {
                setItem_No(e.target.value)
              }}
              list='Item_No'
            />
            <datalist id="Item_No">
              <option></option>
              {uniqueItem_No.map(x => {
                return <option>{x}</option>
              })}
            </datalist>
          </tr>
        </td>
        <td class="col-1">
          <tr>
            <input
              type="text"
              className="form-control form-rounded"
              placeholder="Supplier"
              onBlur={(e) => {
                setSupplier(e.target.value)
              }}
              list='Supplier'
            />
            <datalist id="Supplier">
              <option></option>
              {uniqueSupplier.map(x => {
                return <option>{x}</option>
              })}
            </datalist>
          </tr>
          <tr>
            <input
              type="text"
              className="form-control form-rounded"
              placeholder="Customer"
              onBlur={(e) => {
                setCustomer(e.target.value)
              }}
              list='Customer'
            />
            <datalist id="Customer">
              <option></option>
              {uniqueCUSTOMER.map(x => {
                return <option>{x}</option>
              })}
            </datalist>
          </tr>
        </td>
        <td class="col-1" hidden>
          <tr>
            <input
              type="text"
              className="form-control form-rounded"
              placeholder="Section"
              onBlur={(e) => {
                setSupplier(e.target.value)
              }}
              list='Supplier'
            />
            <datalist id="Supplier">
              <option></option>
              {uniqueSupplier.map(x => {
                return <option>{x}</option>
              })}
            </datalist>
          </tr>
        </td>
        <td class="col-1">
          <tr>
            <input type="button" className="form-control form-rounded btn btn-primary" onClick={clearFilters} value="Clear Filter" />


          </tr>
          <tr>
            <Link to="Add_BOM">
              <input type="button" className="form-control form-rounded btn btn-primary" onClick={clearFilters} value="Add Data" />
            </Link>
          </tr>
        </td>
        <td class='col-1' id='mobileView'>
        </td>
        <td class="col-1">
          <Link to="/BomPivot">
            <button className="form-control form-rounded btn btn-primary">BOM Pivot</button>
          </Link>
        </td>
        <td class='col-2'>
        </td>
        <td class="col-1">
          <button className="btn btn-success" onClick={Edit_toggle} id="mobileView">Edit</button>
        </td>
      </tr>

      <br></br>
      <br></br>
      <br></br>

      <table className="table table-light table-bordered" id='alldata'>
        <thead>
          <tr>
            <th>Part No</th>
            <th>Part Description</th>
            <th>Customer</th>
            <th>Item No</th>
            <th>Department</th>
            <th>Internal Part No</th>
            <th>Comment</th>
            <th>Item Description</th>
            <th>Supplier</th>
            <th>Purchase Unit</th>
            <th>Revised Amount</th>
            <th>Qty Included in the Cost</th>
            <th>BOM Cost</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {bom.map((val, index) => (
            <tr key={index}>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Part_No}
                  onChange={(e) => handleInputChange(e, index, 'Part_No')}
                /> : val.Part_No}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Part_Description_from_part_dataset}
                  onChange={(e) => handleInputChange(e, index, 'Part_Description_from_part_dataset')}
                /> : val.Part_Description_from_part_dataset}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Customer}
                  onChange={(e) => handleInputChange(e, index, 'Customer')}
                /> : val.Customer}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Item_No}
                  onChange={(e) => handleInputChange(e, index, 'Item_No')}
                /> : val.Item_No}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.DEPARTMENT}
                  onChange={(e) => handleInputChange(e, index, 'DEPARTMENT')}
                /> : val.DEPARTMENT}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Internal_Part_No}
                  onChange={(e) => handleInputChange(e, index, 'Internal_Part_No')}
                /> : val.Internal_Part_No}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Comment}
                  onChange={(e) => handleInputChange(e, index, 'Comment')}
                /> : val.Comment}</td>
              <td>{val.Item_Description}</td>
              <td>{val.Supplier}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Purchase_Unit}
                  onChange={(e) => handleInputChange(e, index, 'Purchase_Unit')}
                /> : val.Purchase_Unit}</td>
              <td>{val.Revised_Amount}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Qty_included_in_the_cost}
                  onChange={(e) => handleInputChange(e, index, 'Qty_included_in_the_cost')}
                /> : val.Qty_included_in_the_cost}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.COST_BOM}
                  onChange={(e) => handleInputChange(e, index, 'COST_BOM')}
                /> : val.COST_BOM}</td>
              <td>{editable ?
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={val.Currency}
                  onChange={(e) => handleInputChange(e, index, 'Currency')}
                /> : val.Currency}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ?
        <div className='overlay'>
          <div className="loading-container">
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className='loading'>Loading</p>
          </div>

        </div> : null}
    </div>
  )
}

export default BOM_Dashboard